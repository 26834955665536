import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="31" height="32" viewBox="0 0 31 32" fill="none" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 0C6.93959 0 0 7.16344 0 16C0 24.8366 6.93959 32 15.5 32C24.0604 32 31 24.8366 31 16C31 7.16344 24.0604 0 15.5 0ZM15.0683 13.5586L15.0358 13.005C14.9382 11.5701 15.7947 10.2594 17.15 9.75098C17.6487 9.5702 18.4944 9.5476 19.0473 9.70578C19.2641 9.77357 19.6761 9.99955 19.9689 10.2029L20.5001 10.5758L21.0856 10.3837C21.4108 10.282 21.8445 10.1125 22.0397 9.99955C22.224 9.89786 22.3866 9.84136 22.3866 9.87526C22.3866 10.0673 21.9855 10.7227 21.6494 11.0842C21.194 11.5927 21.3241 11.6379 22.2457 11.2989C22.7986 11.1068 22.8094 11.1068 22.701 11.3215C22.636 11.4345 22.2999 11.8299 21.9421 12.1915C21.3349 12.8129 21.3024 12.8807 21.3024 13.4005C21.3024 14.2027 20.9338 15.8749 20.5652 16.7901C19.8821 18.5075 18.4185 20.2814 16.9548 21.174C14.8948 22.4281 12.1518 22.7445 9.84249 22.0101C9.07272 21.7615 7.75 21.1288 7.75 21.0158C7.75 20.9819 8.15115 20.9367 8.63904 20.9254C9.65818 20.9028 10.6773 20.609 11.5447 20.0893L12.1301 19.7277L11.4579 19.4905C10.5038 19.1515 9.64734 18.3719 9.4305 17.6375C9.36545 17.4002 9.38713 17.3889 9.99428 17.3889L10.6231 17.3776L10.0919 17.1177C9.46302 16.7901 8.8884 16.2364 8.60651 15.6715C8.40052 15.2647 8.14031 14.2366 8.2162 14.1575C8.23789 14.1236 8.46557 14.1914 8.72577 14.2818C9.47387 14.5642 9.57144 14.4964 9.13777 14.0219C8.32462 13.1632 8.07526 11.8864 8.46557 10.6775L8.64988 10.1351L9.36545 10.8695C10.8291 12.3497 12.553 13.231 14.5262 13.4908L15.0683 13.5586Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
