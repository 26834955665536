import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="31" height="32" viewBox="0 0 31 32" fill="none" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 0C6.93959 0 0 7.16344 0 16C0 24.8366 6.93959 32 15.5 32C24.0604 32 31 24.8366 31 16C31 7.16344 24.0604 0 15.5 0ZM12.0921 7.51822C12.9739 7.4768 13.2557 7.46667 15.5008 7.46667H15.4982C17.744 7.46667 18.0247 7.4768 18.9065 7.51822C19.7866 7.55982 20.3876 7.70365 20.9146 7.91467C21.4589 8.13245 21.9187 8.42401 22.3785 8.89868C22.8384 9.373 23.1208 9.84909 23.3327 10.4103C23.5359 10.9529 23.6754 11.573 23.7167 12.4815C23.7563 13.3917 23.7667 13.6826 23.7667 16.0001C23.7667 18.3176 23.7563 18.6078 23.7167 19.518C23.6754 20.4261 23.5359 21.0464 23.3327 21.5891C23.1208 22.1502 22.8384 22.6263 22.3785 23.1006C21.9192 23.5753 21.4587 23.8675 20.9152 24.0855C20.3892 24.2965 19.7878 24.4403 18.9077 24.482C18.0259 24.5234 17.745 24.5335 15.4997 24.5335C13.2548 24.5335 12.9732 24.5234 12.0914 24.482C11.2115 24.4403 10.6106 24.2965 10.0847 24.0855C9.54131 23.8675 9.08009 23.5753 8.62077 23.1006C8.1611 22.6263 7.87866 22.1502 7.66734 21.5889C7.46308 21.0464 7.32375 20.4263 7.28328 19.5178C7.24332 18.6076 7.23333 18.3176 7.23333 16.0001C7.23333 13.6826 7.24367 13.3915 7.28311 12.4813C7.32272 11.5732 7.46222 10.9529 7.66717 10.4102C7.879 9.84909 8.16145 9.373 8.62129 8.89868C9.08078 8.42419 9.542 8.13263 10.0857 7.91467C10.6113 7.70365 11.2121 7.55982 12.0921 7.51822Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.759 9.00409C14.903 9.00385 15.058 9.00393 15.2251 9.004L15.5006 9.00409C17.7079 9.00409 17.9695 9.01226 18.8411 9.05315C19.6471 9.0912 20.0846 9.23022 20.376 9.34702C20.7617 9.50169 21.0368 9.68658 21.3259 9.98525C21.6153 10.2839 21.7944 10.5684 21.9446 10.9666C22.0577 11.267 22.1926 11.7186 22.2293 12.5506C22.2689 13.4502 22.2775 13.7204 22.2775 15.9978C22.2775 18.2751 22.2689 18.5453 22.2293 19.4449C22.1924 20.2769 22.0577 20.7285 21.9446 21.0289C21.7947 21.4271 21.6153 21.7107 21.3259 22.0092C21.0366 22.3079 20.7619 22.4928 20.376 22.6474C20.0849 22.7648 19.6471 22.9034 18.8411 22.9415C17.9696 22.9824 17.7079 22.9913 15.5006 22.9913C13.2933 22.9913 13.0316 22.9824 12.1602 22.9415C11.3542 22.9031 10.9167 22.7641 10.6252 22.6473C10.2394 22.4926 9.96382 22.3077 9.67448 22.009C9.38515 21.7104 9.20603 21.4266 9.05585 21.0282C8.9427 20.7278 8.80785 20.2762 8.77117 19.4442C8.73156 18.5446 8.72363 18.2744 8.72363 15.9956C8.72363 13.7168 8.73156 13.448 8.77117 12.5485C8.80802 11.7165 8.9427 11.2649 9.05585 10.9641C9.20569 10.5659 9.38515 10.2814 9.67448 9.98276C9.96382 9.68409 10.2394 9.4992 10.6252 9.34418C10.9166 9.22684 11.3542 9.08818 12.1602 9.04995C12.9228 9.0144 13.2183 9.00373 14.759 9.00195V9.00409ZM19.9134 10.421C19.3657 10.421 18.9214 10.8791 18.9214 11.4446C18.9214 12.01 19.3657 12.4687 19.9134 12.4687C20.461 12.4687 20.9054 12.01 20.9054 11.4446C20.9054 10.8793 20.461 10.4206 19.9134 10.4206V10.421ZM11.2553 15.9997C11.2553 13.5797 13.1561 11.6175 15.5005 11.6174C17.845 11.6174 19.7453 13.5796 19.7453 15.9997C19.7453 18.4198 17.8451 20.3811 15.5006 20.3811C13.1562 20.3811 11.2553 18.4198 11.2553 15.9997Z" fill="white"/>
      <path d="M15.5007 13.1553C17.0225 13.1553 18.2563 14.4287 18.2563 15.9997C18.2563 17.5706 17.0225 18.8442 15.5007 18.8442C13.9788 18.8442 12.7451 17.5706 12.7451 15.9997C12.7451 14.4287 13.9788 13.1553 15.5007 13.1553Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
