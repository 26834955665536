import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="31" height="32" viewBox="0 0 235 243" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.5 0C52.6065 0 0 54.3035 0 121.29C0 188.277 52.6065 242.581 117.5 242.581C182.393 242.581 235 188.277 235 121.29C235 54.3035 182.393 0 117.5 0ZM137.673 60.6452C137.671 67.6358 140.221 74.3889 144.847 79.6474C154.052 90.2266 166.777 89.6202 166.777 89.6202V110.442C156.336 110.46 146.154 107.206 137.673 101.143V143.527C137.673 164.753 120.413 181.936 99.1603 181.936C79.3288 181.936 60.648 165.966 60.648 143.527C60.648 120.482 81.0886 102.423 104.44 105.523V126.816C92.8657 123.245 81.6301 131.937 81.6301 143.527C81.6301 153.23 89.6169 161.047 99.228 161.047C103.773 161.046 108.14 159.29 111.41 156.147C114.679 153.005 116.597 148.722 116.758 144.201V60.6452H137.673Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
