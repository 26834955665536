import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="31" height="32" viewBox="0 0 31 32" fill="none" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 24.8366 6.93959 32 15.5 32C24.0604 32 31 24.8366 31 16C31 7.16344 24.0604 0 15.5 0C6.93959 0 0 7.16344 0 16ZM12.6583 23.3333L12.9221 19.2548L12.9218 19.2547L20.1093 12.5593C20.4247 12.2703 20.0404 12.1294 19.6216 12.3915L10.7514 18.1682L6.9199 16.9338C6.09247 16.6723 6.08653 16.0853 7.10567 15.6634L22.036 9.72057C22.7179 9.401 23.3761 9.88964 23.1158 10.967L20.5732 23.3354C20.3956 24.2143 19.8811 24.4245 19.1683 24.0185L15.2951 21.0646L13.4333 22.9333C13.4275 22.9392 13.4216 22.9451 13.4158 22.9509C13.2076 23.1602 13.0353 23.3333 12.6583 23.3333Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
