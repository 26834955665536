import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="31" height="32" viewBox="0 0 31 32" fill="none" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 0C6.93959 0 0 7.16344 0 16C0 24.8366 6.93959 32 15.5 32C24.0604 32 31 24.8366 31 16C31 7.16344 24.0604 0 15.5 0ZM21.9594 10.4996C22.6708 10.7011 23.231 11.2948 23.4212 12.0488C23.7667 13.4154 23.7667 16.2667 23.7667 16.2667C23.7667 16.2667 23.7667 19.1179 23.4212 20.4845C23.231 21.2385 22.6708 21.8323 21.9594 22.0339C20.6701 22.4 15.5 22.4 15.5 22.4C15.5 22.4 10.3299 22.4 9.04054 22.0339C8.32914 21.8323 7.76888 21.2385 7.57875 20.4845C7.23333 19.1179 7.23333 16.2667 7.23333 16.2667C7.23333 16.2667 7.23333 13.4154 7.57875 12.0488C7.76888 11.2948 8.32914 10.7011 9.04054 10.4996C10.3299 10.1333 15.5 10.1333 15.5 10.1333C15.5 10.1333 20.6701 10.1333 21.9594 10.4996Z" fill="white"/>
      <path d="M13.9502 19.1995V13.8662L18.0835 16.533L13.9502 19.1995Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
